import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createEntry } from '../Model';
import { Tween } from 'react-gsap';
import '../../scss/Welcome.scss';

export default function Welcome({wsettings,dsettings}){
    const navigate = useNavigate();
    const imgRef = useRef(null);
    const welcRef = useRef(null);
    const discRef = useRef(null);
    const discFullRef = useRef(null);
    const butRef = useRef(null);
    const butFullRef = useRef(null);
    const modalRef = useRef(null);
    const settings = require('../../config.json');

    let disclaimerAction = dsettings.mode; // 0 no disclaimer.
    // 1 = show excerpt disclaimer, continue when clicking yes
    // 2 = show excerpt discliamer, show modal on clicking yes
    // let fullDisclaimerText = '';
    // let fullDisclaimerCTA = '';

    const [ showDisclaimer, setShowDisclaimer ] = useState(false);

    function handleDisclaimerClick(){
        setShowDisclaimer(true);
    }
    async function handleAcceptClick(){
        await createEntry(); // if we don't wait for this to complete before going to q1, it will fail at getSettings.
        navigate('/question1');
    }
    function handleCloseClick(){
        setShowDisclaimer(false);
    }

    // useEffect(()=>{
    //     async function fetchData() {
    //         const settings = await getSettings();
    //         imgRef.current.src = settings.welcome.preview;
    //         welcRef.current.innerHTML = settings.welcome.intro;
    //         discRef.current.innerHTML = settings.disclaimer.excerpt;
    //         discFullRef.current.innerHTML = settings.disclaimer.full;
    //         butRef.current.innerHTML = settings.disclaimer.cta;
    //         butFullRef.current.innerHTML = settings.disclaimer.cta;

    //         // console.log(imgRef,);
    //         disclaimerAction = settings.disclaimer.mode;
    //         // fullDisclaimerText = settings.disclaimer.full;
    //         // fullDisclaimerCTA = settings.disclaimer.cta;
    //     }
    //     fetchData();
    // },[]);


    return (

        <div className="fullscreen welcome">
            <Tween from={{ opacity:0, y:50, stagger:.2 }}>
            <h1>{wsettings.title}</h1>
            <p ref={welcRef}>{wsettings.intro}</p>
            <img src={wsettings.preview} alt="preview graphic" ref={imgRef} />
            <h3>Disclaimer</h3>
            <p ref={discRef} dangerouslySetInnerHTML={{__html:dsettings.excerpt}}></p>
            <button className='dark primary control-cta' onClick={handleAcceptClick} ref={butRef}>{dsettings.cta}</button>
            {/* <button onClick={handleAcceptClick} ref={butRef}>{dsettings.cta}</button> */}
            {/* <section className="App-controls">
                <img src={settings.logoURL} className="App-logo dark" alt='logo' />
                <button className='dark primary' onClick={handleAcceptClick} ref={butRef}>{dsettings.cta}</button>
            </section> */}
            </Tween>
            {showDisclaimer && <div className="modal" ref={modalRef}>
                <h2>Disclaimer</h2>
                <p ref={discFullRef} dangerouslySetInnerHTML={{__html:dsettings.full}}></p>
                <button onClick={handleCloseClick} ref={butFullRef}>{dsettings.close}</button>
            </div>}
        </div>
    );
}