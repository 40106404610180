import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getEntry, setProfile } from '../Model';
// import { Tween } from 'react-gsap';
import '../../scss/Signup.scss';

export default function Signup({settings}){
    const { id } = useParams();
    const [ errors, setErrors ] = useState({});
    const [ submitted, setSubmitted ] = useState(false);
    const [ interacted, setInteracted ] = useState(false);
    const [ userImage, setUserImage ] = useState(null);

    const fields = [ 
        {label:"First name",type:"text",id:"firstname"},
        {label:"Last name",type:"text",id:"lastname"},
        {label:"Email address",type:"email",id:"email",required:true},
        // {label:"Wallet address (optional)",type:"text",id:"walletid"},
        {label:"I consent",type:"checkbox",id:"waitlist"},
        // {label:"Get an NFT of this photo",type:"checkbox",id:"nftoptin"}
    ];

    function handleInteracted(){
        setInteracted(true);
        window.scrollTo(0, 0);
    }

    async function handleSubmit(){
        const err = {};
        const data = {};
        fields.forEach( (field,index) => {
            const input = document.getElementById(field.id);
            if (field.required && input.value===''){ // todo. proper validation
                err[field.id] = true;
            }
            data[field.id] = (field.type==='checkbox') ? input.checked : input.value;
        });
        setErrors(err);
        if(JSON.stringify(err)==='{}') { // check if err is empty.
            console.log('submit!',data);
            const result = await setProfile(data);
            console.log(result);
            if (result.status==200){
                setSubmitted(true);
            } else {
                setErrors({email:true});
            }
            
        }
    }

    useEffect( () => {
        console.log('load user',id);

        // declare the data fetching function
        const fetchData = async () => {
            const data = await getEntry(id);
            console.log(data);
            if (data){
                setUserImage(data.afterpic);
                if (data.email) {
                    setInteracted(true);
                    setSubmitted(true);
                }
            } else {
                setErrors({sessionid:true});
                setInteracted(true);
            }
        }

        // call the function
        fetchData()
        // make sure to catch any error
        .catch(console.error);
    },[id]);

    return (
        <div className="fullscreen signup mobile">


            {/* error state */}
            { errors.sessionid
            ? <>
                    <h1>User not found</h1>
                    <h2>Please check your link and try again.</h2>
              </>
            : <>
            
            {submitted 
            ?
                <h1>{ settings.emailsuccess.title }</h1>
            :
                <h1>{ settings.email.title }</h1>
            }
            
            {userImage && <>
                <img src={userImage} alt="this is you!" />
            </>}

            {submitted ? 
            <>
                {/* <a className="button" href={userImage} download>Download photo</a> */}
                <p dangerouslySetInnerHTML={{__html:settings.emailsuccess.body}}></p>
            </>
            :
            <>
                {/* <p>Thank you for participating! Please provide your email below to receive a copy of your photo.</p>
                <p>Please provide your wallet address to receive a special NFT of your photo and get early access to Astound.</p> */}
                <div className='field-wrap'>
                    {fields.map( (field,index) => (
                        <div className={'wrap-'+field.type+(errors[field.id]?' error':'')} key={index}>
                            <label htmlFor={field.id}>{field.label}</label>
                            <input type={field.type} id={field.id}></input>
                        </div>
                    ) )} 
                     <input type="submit" value={ settings.email.formaction } onClick={handleSubmit}></input>
                </div>
            </>
            }

            </>}
        </div>
    );
}