import React, { Component, useState, useEffect, useRef } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Tween } from 'react-gsap';
import { getEntry, setSetting, getSetting } from './Model';

export default function Question({settings,foreground,background}) {
    const navigate = useNavigate();
    const [ answer, setAnswer ] = useState( getSetting('question'+settings.qNum) );
    const [ isActive, setActive ] = useState(answer!=undefined);  
    const msettings = require('../config.json');

    function getPreviousAnswer(){
        const prevnum = settings.qNum-1;
        const prevanswer = getSetting('question'+prevnum);
        console.log(prevnum,prevanswer);
        return prevanswer;
    }

  function next(){
   console.log('calling next()');
   console.log(settings.qNum);
    {settings.qNum == "1" ? navigate('/question2',1)
      : settings.qNum == "2" ? navigate('/takepic',1)
      : navigate('/takepic',1);
    }
  }
  function back(){
    {settings.qNum == "1" ? navigate('/welcome',-1)
      : settings.qNum == "2" ? navigate('/question1',-1)
      : navigate('/welcome',-1);
    }
  }

  function handleOptionClick(e){
    
    // const currentQ = getSetting('currentQ');
    const val = e.target.getAttribute('id');
    console.log(settings.qNum,val);
    setAnswer(val);
    setSetting('question'+settings.qNum,val);
    // logic for setting paths goes here. only on last question.
    if (settings.qNum==='2'){

        const isPixel = getSetting('question1')==='pixel';
        const path = '/images/astound/bg/';
        const ext = isPixel ? '.png' : '.jpg';

    //     const pathobj = settings.paths.find(el=>el.answer===answers);
    //     const paths = pathobj.value.split(',');

        setSetting('fgpath',process.env.REACT_APP_URL+foreground.path); // set this in session, so that camera can pick it up.
        const bglist = background[val];
        const i = Math.floor( Math.random() * (bglist.length-1) );
        setSetting('bgpath',process.env.REACT_APP_URL+path+bglist[i]+ext);
        console.log('set',foreground.path,i,path+bglist[i]+ext);

    //     console.log('answers',answers,paths);

    }

    setActive(true);
    // setSetting('currentQ',val);
    // console.log(currentQ);
  }

    return (
        <div className={`fullscreen questions ${settings.page}`}>
            <Tween from={{ y:50, alpha:0, stagger:.2 }}>
                <h1>
                    <span>AI</span>MAGINE    
                </h1>
                <h2><span className='text-lg'>{settings.titleLine2}</span></h2>
                <div className='grid-wrap'>                        
                    {settings.excerpt != "" && <p>{settings.excerpt}</p>}
                    <div className='q-grid'>
                        
                        { settings.options.map( (option,index) => (
                            <button onClick={handleOptionClick} key={index} data-index={index} id={option.id} className={`question ${option.id===answer?'selected':''}`}>
                            { (option.label != "") &&
                                <span>{option.label}</span>
                            }  
                            { (option.imgURL != "") &&
                                <img src={option.imgURL} />
                            }
                            </button>
                            )
                        )}
                    </div>

                </div>
           
                <div className='selected-bg'>
                    {settings.qNum==='1' ? 
                        <div> <span className='align-right'>Original</span>
                        <div className='bg-preview'>
                            <img src='../../images/astound/original.jpg' />
                        </div></div>
                    : settings.qNum==='2' ? <span>Selected Style: <span className='highlight'>{ getPreviousAnswer() }</span></span>
                    : <></>
                    }
                    
                </div>
               
            </Tween>
            <button id="nexrLink" onClick={next} className='control-cta light primary' disabled={!isActive}>{settings.ctaNext}</button>
            
        </div> 
    );
}
