import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import QRCode from 'qrcode';
import { getAfterImage, getSessionID, resetSession } from '../Model';
import { Tween } from 'react-gsap';
import '../../scss/Preview.scss';

const APP_URL = process.env.REACT_APP_URL;

export default function Preview({settings}){
    const navigate = useNavigate();
    const imgsrc = getAfterImage();
    const qrRef = useRef(null);
    const emailRef = useRef(null);

    const [ state, setState ] = useState('preview');
    const [ interacted, setInteracted ] = useState(false);

    function handleRetake(){
        navigate('/takepic');
    }
    function handleDone(){
        resetSession();
        navigate('/');
    }
    function handleEmail(){
        setState('email');
        setInteracted(true);
    }
    function handleQR(){
        setState('qr');
        setInteracted(true);
        const page = APP_URL+'/signup/'; // get actual url
        const id = getSessionID();
        // console.log(page);
        QRCode.toDataURL(page+id)
        .then(url => {
            // console.log(url)
            qrRef.current.src = url;
        })
        .catch(err => {
            // console.error(err)
        })
    }
    function sendEmail(){
        // todo. send email logic.
        setState('preview');
    }
    function reset(){
        setState('preview');
    }

    return (
        <div className="fullscreen preview">
            <Tween from={{ opacity:0, y:50, stagger:.2 }}>
                <div className="nav">
                    <span onClick={handleDone}>Done</span>
                    {!interacted && <span onClick={handleRetake}>Retake</span>}
                </div>
                <div className="preview-wrapper">
                    <img src={imgsrc} alt="this is you!" />
                </div>
                <div className="options">
                    {!interacted && <button onClick={handleRetake}>Retake</button>}
                    {settings.email.enabled && 
                        <button onClick={handleEmail}>{settings.email.cta}</button>}
                    {settings.qrcode.enabled && 
                        <button onClick={handleQR}>{settings.qrcode.cta}</button>}
                </div>
            </Tween>
            {(state==='email' || state==='qr') && 
                <div className="modal">
                    {state==='email' && <div>
                            <p>{settings.email.label}</p>
                            <input type="text" id="email" ref={emailRef} />
                            <button onClick={sendEmail}>{settings.email.action}</button>
                        </div>}
                    {state==='qr' && <div>
                            <p>{settings.qrcode.label}</p>
                            <img ref={qrRef} alt="QR Code" />
                            <button onClick={handleDone}>{settings.qrcode.action}</button>
                        </div>}
                </div>}
        </div>
    );
}